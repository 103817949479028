.sidemenu {
  transform: translateX(-100%);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.sidemenu.active {
  /* left: 0px; */
  transform: translateX(0px);
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
